<app-layout>
  <div class="container">
    <h1>{{'dashboard.title' | translate}}</h1>

    <div class="button-sepperator">
      <!-- <button mat-flat-button disabled>{{'common.filter' | translate }} <mat-icon>filter_list
        </mat-icon></button> -->
      <div style="width:91px;"></div>

      <button mat-flat-button (click)="onRefresh()">
        <mat-icon>cached</mat-icon>
      </button>
    </div>

    <table mat-table [dataSource]="users" class="mytable dark-header" style="width:100%;margin-top:10px">

      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> {{'common.id' | translate}} </th>
        <td mat-cell *matCellDef="let user" [class.disabled]="!user.isBadgedIn"> {{user.id}} </td>
      </ng-container>

      <!-- username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef> {{'common.username' | translate}} </th>
        <td mat-cell *matCellDef="let user" [class.disabled]="!user.isBadgedIn"> <a
            [routerLink]="['/', 'people', user.id]">{{user? user.username : ''}}</a> </td>
      </ng-container>


      <!-- start_time Column -->
      <ng-container matColumnDef="start_time">
        <th mat-header-cell *matHeaderCellDef> {{'timeentry.start_time'|translate}} </th>
        <td mat-cell *matCellDef="let user" [class.disabled]="!user.isBadgedIn">
          {{ user.isBadgedIn? (user.openentry.start_time | date:'HH:mm:ss':'+0') : user.timeentries.length > 0?
          (user.timeentries[user.timeentries.length-1].start_time | date:'HH:mm:ss':'+0') : '' }}
        </td>
      </ng-container>

      <!-- end_time Column -->
      <ng-container matColumnDef="end_time">
        <th mat-header-cell *matHeaderCellDef> {{'timeentry.end_time'|translate}} </th>
        <td mat-cell *matCellDef="let user" [class.disabled]="!user.isBadgedIn">
          {{(!user.isBadgedIn && user.timeentries.length > 0? (user.timeentries[user.timeentries.length-1].end_time |
          date:'HH:mm:ss':'+0') : '')}}
        </td>
      </ng-container>


      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{'common.date'|translate}} </th>
        <td mat-cell *matCellDef="let user" [class.disabled]="!user.isBadgedIn">
          {{(user.timeentries.length > 0? (user.timeentries[user.timeentries.length-1].start_time | date:'EE, dd.MM.YYYY') :
          '')}}
        </td>
      </ng-container>

      <!-- time Column -->
      <ng-container matColumnDef="time" style="max-width:24px">
        <th mat-header-cell *matHeaderCellDef style="max-width:24px"> {{'common.time'|translate}} </th>
        <td mat-cell *matCellDef="let user" [class.disabled]="!user.isBadgedIn" style="max-width:24px">
          {{!user.isBadgedIn && user.timeentries.length > 0? ((user.timeentries[user.timeentries.length-1].time / 60) |
          number:'1.2-2') +'h': ''}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
</app-layout>